@import 'shared.scss';

#root {
  #Preloader {
    @include flex-center;
    @include position-100(fixed);
    background-color: $blue;
  }

  .Footer {
    opacity: 0;
    @include z-index(foter);
    display: flex;
    position: relative;
    align-items: center;
    padding: var(--space-md) var(--space-sm);
    text-align: center;
    background-color: var(--main-white);
    color: var(--main-black);

    @include media('>=medium') {
      padding: var(--space-lg) var(--space-lg) var(--space-md);
    }

    @include media('>=large') {
      padding: var(--space-xl) var(--space-md) var(--space-lg);
      position: absolute;
      bottom: 0;
    }
    @include media('>=mlarge') {
      padding: var(--space-xl) var(--space-xl) var(--space-lg);
    }

    .footer-brand {
      width: 45%;
      display: flex;
      flex-direction: column;
      order: 1;
      text-align: left;
      padding-right: 10%;
      border-right: 1px solid $grey;
      @include media('>=large') {
        width: 40%;
      }
      @include media('>=mlarge') {
        width: 30%;
      }
      @include media('>=fullhd') {
        width: 40%;
      }
      a {
        margin-bottom: var(--space-sm);
        width: px-to-rem(120);
        @include media('>=medium') {
          width: px-to-rem(200);
        }
        @include media('>=large') {
          width: px-to-rem(280);
        }
        img {
          width: 100%;
        }
      }
      span {
        color: $grey;
        font-size: var(--text-xxs);
      }
    }
    .footer-nav {
      order: 2;
      width: 50%;
      padding-left: 10%;
      color: var(--main-black);
      text-align: left;
      @include media('>=large') {
        width: 60%;
      }
      @include media('>=mlarge') {
        padding-left: 0;
        text-align: right;
        width: 70%;
      }
      @include media('>=fullhd') {
        width: 60%;
      }
      .nav-list {
        li {
          display: block;
          font-size: auto;
          font-size: var(--text-xs);
          margin-bottom: px-to-rem(6);
          @include media('>=large') {
            font-size: calc(var(--text-sm) * 0.9);
            margin-bottom: px-to-rem(8);
          }
          @include media('>=mlarge') {
            margin-bottom: 0;
            display: inline-block;
            // font-size: calc(var(--text-sm) * 0.9);
          }
          a {
            text-decoration: none;
            color: darken($grey, 20%);
          }
        }
      }
    }
    .footer-copyright {
      display: none;
    }
  }

  .RotateScreen {
    @include z-index(rotate);
  }
}

.vs-scrollbar {
  z-index: 100;
  .vs-scrolldrag {
    background: $gold !important;
  }
}
.vs-scrollbar.vs-vertical {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 7px !important;
  height: 100% !important;
  transform: none !important;
}

.CookieConsent {
  background: rgba($blue, 0.98);
  color: $white;
  position: fixed;
  text-align: center;
  padding: calc(var(--space-md) * 0.9);
  bottom: px-to-rem(10) !important;
  right: 5%;
  height: px-to-rem(120);
  width: 90%;
  @include z-index(cookiebar);
  @include media('>=medium') {
    right: px-to-rem(10);
    padding: var(--space-md);
    width: px-to-rem(320);
    height: px-to-rem(135);
  }
  @include media('>=large') {
    width: px-to-rem(400);
    height: px-to-rem(170);
    bottom: px-to-rem(20) !important;
    right: px-to-rem(20);
  }
  @include media('>=hd') {
    width: px-to-rem(460);
    height: px-to-rem(200);
  }
  @include media('>=mlarge') {
    width: px-to-rem(480);
  }
  > div {
    font-weight: 300;
    line-height: 1.4;
    font-size: var(--text-sm);
    margin-bottom: px-to-rem(10);
    @include media('>=large') {
      font-size: var(--text-sm);
    }
    @include media('>=hd') {
      font-size: calc(var(--text-sm) * 1.2);
    }
    @include media('>=mlarge') {
      font-size: calc(var(--text-sm) * 1.3);
    }
  }
  button {
    white-space: nowrap;
    text-decoration: none;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    color: $gold;
    font-size: var(--text-sm);
    padding: var(--space-md);
    border: 1px solid $red;
    padding: var(--space-xs) var(--space-sm);
    @include media('>=medium') {
      font-size: calc(var(--text-sm) * 1.1);
    }
    @include media('>=hd') {
      border: 2px solid $red;
    }

    &:hover {
      color: lighten($gold, 10%);
      transition: color 0.3s ease;
    }
  }
}
