@import 'src/style/shared.scss';

.header__nav-content {
  position: fixed;
  width: 100%;
  height: 0;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: translateX(100%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include z-index(sidebar);
  height: 100%;
  border-left: none;
  background: $blue !important;
  transition: none;
  text-align: center;
  padding: 0 9%;
  overflow: hidden;
  @include media('>=medium') {
    padding: 0 14%;
  }
  @include media('>=large') {
    padding: 0 14% 20vh;
    flex-direction: row;
    justify-content: flex-end;
  }
  @include media('>=fullhd') {
    padding: 0 18% 20vh;
  }
  @include media('>=xlarge') {
    padding: 0 22% 20vh;
  }

  nav.main-nav {
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    padding-top: 35vw;
    padding-bottom: var(--space-lg);
    @include media('>=xsmall') {
      padding-bottom: calc(var(--space-xl) * 0.8);
    }
    @include media('>=medium') {
      padding-bottom: var(--space-xl);
    }
    @include media('>=large') {
      width: 65%;
      padding-bottom: 0;
      padding-top: 0;
    }
    ul.main-nav__menu {
      @include clean;
      list-style-type: none;
    }
    .main-nav__menu-li {
      display: block;
      margin-bottom: 3.5vh;
      opacity: 0;
      &:last-of-type {
        margin-bottom: 0;
      }

      @include media('>=large') {
        margin-bottom: 5vh;
      }

      &:last-child {
        margin-bottom: 0;
      }
      a {
        text-transform: uppercase;
        font-family: var(--font-primary);
        text-decoration: none;
        color: $white;
        transition: color 0.6s ease;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: calc(var(--text-lg) * 0.7);
        padding: px-to-rem(6) px-to-rem(10);
        @include media('>=xsmall') {
          font-size: calc(var(--text-lg) * 0.8);
          padding: px-to-rem(8) px-to-rem(12);
        }
        @include media('>=medium') {
          font-size: var(--text-xl);
        }
        @include media('>=large') {
          font-size: calc(var(--text-xxl) * 0.8);
        }
        @include media('>=hd') {
          font-size: calc(var(--text-xxl) * 0.9);
        }
        @include media('>=mlarge') {
          font-size: var(--text-xxl);
        }
        @include media('>=1600px') {
          font-size: var(--text-xxxl);
        }
        &:hover {
          text-decoration: none;
          color: $gold;
        }
        &.active {
          text-decoration: none;
          color: $gold;
          border: 2px solid $red;
          position: relative;

          padding: px-to-rem(4) px-to-rem(8);
          @include media('>=xsmall') {
            padding: px-to-rem(6) px-to-rem(10);
          }
        }
      }
    }
  }

  .info-nav {
    z-index: 1;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: left;
    padding-bottom: var(--space-lg);
    @include media('>=xsmall') {
      padding-bottom: calc(var(--space-xl) * 0.8);
    }
    @include media('>=medium') {
      padding-bottom: var(--space-xl);
    }
    @include media('>=large') {
      padding-bottom: 0;
      width: 35%;
    }
    @include media('>=mlarge') {
      // width: 30%;
    }

    .info-nav__item {
      text-transform: uppercase;
      text-decoration: none;
      opacity: 0;
      font-family: var(--font-primary);
      letter-spacing: 1px;
      font-size: calc(var(--text-md) * 0.8);
      padding: px-to-rem(6) px-to-rem(10);
      position: relative;
      margin-bottom: var(--space-xs);

      span {
        line-height: 1;
        @include clean;
        color: $white;
        transition: color 0.6s ease;
      }

      @include media('>=xsmall') {
        // font-size: calc(var(--text-md) * 0.9);
        padding: px-to-rem(8) px-to-rem(12);
      }
      @include media('>=medium') {
        font-size: calc(var(--text-xl) * 0.9);
      }
      @include media('>=large') {
        top: px-to-rem(2);
        font-size: calc(var(--text-xxl) * 0.6);
      }
      @include media('>=hd') {
        font-size: calc(var(--text-xxl) * 0.7);
      }
      @include media('>=1600px') {
        font-size: calc(var(--text-xxxl) * 0.7);
      }

      &--active {
        text-decoration: none;
        color: $gold;
        position: relative;
        border: 2px solid $red;
        padding: px-to-rem(4) px-to-rem(8);
        @include media('>=xsmall') {
          padding: px-to-rem(6) px-to-rem(10);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        text-decoration: none;
        span {
          color: $gold;
        }
      }
    }
  }

  .social-nav {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    display: flex;
    padding-left: px-to-rem(12);
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.6s 0.5s;

    .Header--active & {
      transform: translateY(0);
      transition: all 0.6s 0.8s;
      opacity: 1;
    }

    // @include media('>=xsmall') {
    //   margin-right: calc(var(--space-sm) * 0.8);
    //   padding-right: calc(var(--space-sm) * 0.8);
    // }
    @include media('>=large') {
      display: none;
    }

    &__insta {
      border: 0;
      @include clean;
      display: inline-block;
      width: px-to-rem(20);
      height: px-to-rem(20);
      margin-right: calc(var(--space-sm) * 1.3);
      position: relative;
      top: px-to-rem(1);
      @include media('>=medium') {
        margin-right: var(--space-md);
        width: px-to-rem(25);
        height: px-to-rem(25);
      }
    }

    &__fb {
      border: 0;
      @include clean;
      display: inline-block;
      width: px-to-rem(20);
      height: px-to-rem(20);
      margin-right: calc(var(--space-sm) * 1.3);
      @include media('>=medium') {
        margin-right: var(--space-md);
        width: px-to-rem(25);
        height: px-to-rem(25);
      }
    }

    &__trip {
      border: 0;
      position: relative;
      top: px-to-rem(1);
      @include clean;
      display: inline-block;
      width: px-to-rem(24);
      height: px-to-rem(24);
      @include media('>=medium') {
        top: px-to-rem(2);
        width: px-to-rem(32);
        height: px-to-rem(32);
      }
    }

    svg {
      @include fill;
      fill: $gold;
      opacity: 0.7;
      transition: opacity 0.4s ease;
    }
  }

  &__bg {
    svg {
      position: absolute;
      width: px-to-rem(400);
      top: px-to-rem(-80);
      right: px-to-rem(-100);
      opacity: 0;
      transform-origin: 50% 50%;

      g {
        fill: lighten($blue, 1%);
      }

      @include media('>=xsmall') {
        width: px-to-rem(450);
        right: px-to-rem(-130);
      }
      @include media('>=small') {
        top: px-to-rem(-120);
        width: px-to-rem(500);
        right: px-to-rem(-150);
      }

      @include media('>=medium') {
        width: px-to-rem(800);
        top: px-to-rem(-300);
        right: px-to-rem(-250);
      }
      @include media('>=large') {
        width: px-to-rem(1000);
        top: px-to-rem(-400);
        right: px-to-rem(-150);
      }
      @include media('>=mlarge') {
        width: px-to-rem(1400);
        top: px-to-rem(-550);
        right: px-to-rem(-150);
      }
    }
  }
}
