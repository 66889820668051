:root {
  --color-red: #e30613;

  --color-gold: #9d8e63;
  --color-gold-darker: #7c7258;

  --main-white: white;
  --main-black: #252525;
  --main-grey: #949494;
  --main-blue: #272f39;

  --grey-9: #232323;
  --grey-8: #353535;
  --grey-7: #474747;
  --grey-6: #757575;
  --grey-5: #a3a3a3;
  --grey-4: #bfbfbf;
  --grey-3: #dadada;
  --grey-2: #ededed;
  --grey-1: #f6f6f6;
}

.color-text-green {
  color: var(--color-green);
}
.color-text-blue {
  color: var(--color-blue);
}
