@import 'src/style/shared.scss';

.Pages {
  position: relative;
  text-align: center;
  @include z-index(pages);
  flex-grow: 1;

  & > section {
    width: 100%;
    opacity: 0;
    border-bottom: 1px solid $grey;
  }
}
