$base-path: '../../assets/fonts/';

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('#{$base-path}Campton/campton_light.woff') format('woff'),
    url('#{$base-path}Campton/campton_light.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$base-path}Campton/campton_book.woff') format('woff'),
    url('#{$base-path}Campton/campton_book.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('#{$base-path}Campton/campton_medium.woff') format('woff'),
    url('#{$base-path}Campton/campton_medium.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('#{$base-path}Campton/campton_bold.woff') format('woff'),
    url('#{$base-path}Campton/campton_bold.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
