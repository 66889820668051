:root {
  --container-width-xs: 37.5rem;
  --container-width-sm: 45rem;
  --container-width-md: 64rem;
  --container-width-lg: 80rem;
  --container-width-xl: 90rem;
}

// classes used to center content on x-axis and set content max width
.container {
  width: calc(100% - 2 * var(--component-padding));
  margin-left: auto;
  margin-right: auto;
  max-width: px-to-rem(1400);
}
