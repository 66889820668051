@import 'src/style/shared.scss';

.HamburgerBtn {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  color: inherit;
  position: relative;
  @include flex-center;

  body.preloader & {
    pointer-events: none;
  }

  &__bars {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 1.8rem;
    height: 1rem;
    @include media('>=medium') {
      width: 2.4rem;
      height: 1.4rem;
    }
    @include media('>=large') {
      width: 2.8rem;
      height: 1.7rem;
    }

    &__bar {
      width: 100%;
      background: $white;
      position: relative;
      height: 2px;
      transition: transform 0.3s ease;
      transform-origin: top left;
      @include media('>=medium') {
        height: 3px;
      }
    }
  }

  &.close {
    .HamburgerBtn__bars {
      margin-right: 0.2rem;
      width: 1.6rem;
      height: 1.2rem;
      @include media('>=medium') {
        margin-right: 0.4rem;
        width: 2rem;
        height: 1.6rem;
      }
      @include media('>=large') {
        margin-right: 0.2rem;
        width: 2.6rem;
        height: 2rem;
      }
    }

    .HamburgerBtn__bars__bar {
      &:nth-child(1) {
        transform: translateX(1px) rotate(45deg);
      }

      &:nth-child(2) {
        transform: scaleX(0);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

  &.back {
    .HamburgerBtn__bars__bar {
      &:nth-child(1) {
        transform: translateX(-1px) translateY(10px) rotate(-45deg) scaleX(0.8);
      }

      &:nth-child(2) {
        transform: scaleX(0);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateX(1px) translateY(-9px) rotate(45deg) scaleX(0.8);
      }
    }
  }
}
