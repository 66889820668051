@import 'src/style/shared.scss';

.Header__top-bar {
  opacity: 0;
  top: 0;
  left: 0;
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include z-index(main-nav);
  padding: var(--space-sm) calc(var(--space-md) * 0.8) calc(var(--space-sm) * 0.9);
  margin-top: var(--space-sm);
  background-color: var(--main-blue);
  margin-left: 5%;
  width: 90%;
  transform: translateY(0);
  transition: transform 0.6s ease, background-color 0.3s ease 0.6s;

  @include media('>=medium') {
    margin-top: var(--space-md);
    padding: calc(var(--space-md) * 0.9) calc(var(--space-md) * 1.1);
    margin-left: 8%;
    width: 84%;
  }

  @include media('>=large') {
    margin-top: calc(var(--space-md) * 0.8);
    justify-content: flex-start;
    padding: calc(var(--space-md) * 0.6) calc(var(--space-md) * 1.1);
    margin-left: 9%;
    width: 82%;
  }

  @include media('>=hd') {
    margin-left: 10%;
    width: 80%;
  }

  @include media('>=mlarge') {
    // padding: calc(var(--space-md) * 0.7) calc(var(--space-md) * 1.1);
    margin-left: 12%;
    width: 76%;
  }

  @include media('>=fullhd') {
    // padding: calc(var(--space-md) * 0.8) calc(var(--space-md) * 1.1);
    margin-left: 15%;
    width: 70%;
  }

  @include media('>=xlarge') {
    margin-left: 20%;
    width: 60%;
  }

  &--isHidden {
    // opacity: 0;
    // visibility: hidden;
    transform: translateY(-200%);
    transition: transform 0.7s ease;
  }

  .Header--active & {
    background-color: transparent;
    transition: transform 0.6s ease, background-color 0.3s ease 0.6s;

    &.Header__top-bar--isHidden {
      transform: none;
    }
  }

  h1 {
    @include clean;

    >span,
    .site-description {
      @extend %hide-element;
    }
  }

  a.main-logo {
    @include clean;
    line-height: 100%;
    display: block;
    width: px-to-rem(140);

    @include media('>=xsmall') {
      width: px-to-rem(150);
    }

    @include media('>=medium') {
      width: px-to-rem(200);
    }

    @include media('>=large') {
      width: px-to-rem(280);
    }

    @include media('>=fullhd') {
      width: px-to-rem(290);
    }

    img {
      @include clean;
      width: 100%;
    }
  }

  &__social {
    display: none;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: var(--space-lg);
    padding-right: var(--space-lg);
    border-right: 1px solid rgba($white, 0.1);
    height: 100%;

    @include media('>=large') {
      display: flex;
    }

    &__insta {
      border: 0;
      @include clean;
      display: inline-block;
      margin-right: var(--space-md);
      width: px-to-rem(30);
      height: px-to-rem(30);
      position: relative;
      top: px-to-rem(1);
    }

    &__fb {
      border: 0;
      @include clean;
      display: inline-block;
      margin-right: var(--space-md);
      width: px-to-rem(30);
      height: px-to-rem(30);
    }

    &__trip {
      border: 0;
      position: relative;
      @include clean;
      display: inline-block;
      top: px-to-rem(2);
      width: px-to-rem(36);
      height: px-to-rem(36);
    }

    svg {
      @include fill;
      fill: $gold;
      opacity: 0.5;
      transition: opacity 0.4s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}