:root {
  --btn-font-size: 1.1em;
  // --btn-sm: calc(var(--btn-font-size) - 0.2em);
  // --btn-md: calc(var(--btn-font-size) + 0.2em);
  // --btn-lg: calc(var(--btn-font-size) + 0.4em);

  --btn-radius: var(--radius);
}

@include media('>=medium') {
  :root {
    --btn-font-size: 1.2em;
  }
}
@include media('>=large') {
  :root {
    --btn-font-size: 1.3em;
  }
}

button,
input[type='reset'],
input[type='submit'],
input[type='button'] {
  background: none;
  border: 0;
  cursor: pointer;
  overflow: visible;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-primary);
  width: auto;
  -webkit-appearance: none;
  background-clip: padding-box;
  border-radius: 0;
  box-sizing: border-box;
  line-height: normal !important;
  font-weight: bold;
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.btn {
  display: inline-flex;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: var(--btn-font-size);
  color: $gold;
  margin: var(--space-xxs) 0 var(--space-xs);
  transition: color 0.3s;
  padding: var(--space-md);
  border: 2px solid $red;
  padding: var(--space-xs) var(--space-sm);
  &:hover {
    color: lighten($gold, 10%);
  }
  &:active,
  &:focus {
    text-decoration: none;
  }
}
